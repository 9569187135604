import React, { useEffect } from "react";
import "./Modal.scss";
import { Button } from "react-bootstrap";
import { useStoreActions, useStoreState } from "easy-peasy";
import { motion } from "framer-motion";
import Arrow from "../../Assets/Icons/arrow.png";
import { createSupportRequest } from "../../Services/Messages/LiveSupport";
import { useParams } from "react-router-dom";
import Lottie from "lottie-react";
import Done from "../../Assets/Animations/Done.json";

const Modal_live_support = (props) => {
  let { id } = useParams();

  if (props.page === 0) {
    id = process.env.REACT_APP_ZAPPY_ID;
  }
  //Global States

  //Local States
  const [emailError, setEmailError] = React.useState(false);
  const [usernameError, setUsernameError] = React.useState(false);
  const [mobileError, setMobileError] = React.useState(false);
  const [mobileNumber, setMobileNumber] = React.useState("");
  const [localEmail, setLocalEmail] = React.useState("");
  const [localUsername, setLocalUsername] = React.useState("");
  const [requestDone, setRequestDone] = React.useState(false);

  // useEffect(() => {
  //   setLocalEmail(localStorage.getItem("email"));
  //   setLocalUsername(localStorage.getItem("username"));
  // }, []);

  if (!props.show) {
    return null;
  }

  const checkValidations = () => {
    if (document.getElementById("email").value === "") {
      setEmailError(true);
    } else {
      if (!document.getElementById("email").value.includes("@")) {
        setEmailError(true);
        return;
      }
      setEmailError(false);
    }
    if (document.getElementById("username").value === "") {
      setUsernameError(true);
    }

    if (
      document.getElementById("email").value === "" ||
      document.getElementById("username").value === "" ||
      document.getElementById("email").value.includes("@") === false ||
      document.getElementById("username").value.length < 3 ||
      document.getElementById("mobile").value.length < 10 ||
      document.getElementById("mobile").value === ""
    ) {
      return;
    } else {
      createSupportRequest({
        user_email: document.getElementById("email").value,
        user_name: document.getElementById("username").value,
        phone: mobileNumber,
        bot_id: id,
      })
        .then((res) => {
          console.log(res);
          if (res.status === 201) {
            setRequestDone(true);
            // setTimeout(() => {
            //   props.onClose();
            // }, 1000);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <div className="modalLiveSupport">
        <motion.div
          id="chatbox"
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 0.3,
            ease: [0, 0.71, 0.2, 1.01],
            scale: {
              type: "spring",
              damping: 20,
              stiffness: 100,
              restDelta: 0.001,
            },
          }}
          style={{
            display: "flex",
          }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-title">{props.title}</div>
              <div className="modal-close"onClick={() => {props.onClose();}}
              style={{ visibility: requestDone ? "hidden" : "" }}
              >X</div>
            </div>
            {!requestDone ? (
              <div
                className="modal-body"
                style={{ fontSize: props.fontSize ? props.fontSize : "" }}
              >
                {props.message}
                <div className="inputs">
                  <motion.div
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{
                      duration: 0.3,
                      delay: 0.1,
                      ease: [0, 0.71, 0.2, 1.01],
                      scale: {
                        type: "spring",
                        damping: 20,
                        stiffness: 100,
                        restDelta: 0.001,
                      },
                    }}
                  >
                    <input
                      id="email"
                      value={emailError ? "" : localEmail}
                      placeholder={
                        emailError
                          ? "Please provide a valid email"
                          : "Confirm your email"
                      }
                      style={{
                        border: emailError ? "1px solid red" : "",
                      }}
                      onChange={(e) => {
                        setLocalEmail(e.target.value);
                      }}
                    />
                  </motion.div>
                  <motion.div
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{
                      duration: 0.3,
                      delay: 0.2,
                      ease: [0, 0.71, 0.2, 1.01],
                      scale: {
                        type: "spring",
                        damping: 20,
                        stiffness: 100,
                        restDelta: 0.001,
                      },
                    }}
                  >
                    <input
                      id="username"
                      value={usernameError ? "" : localUsername}
                      style={{
                        marginTop: "1.313rem",
                        border: usernameError ? "1px solid red" : "",
                      }}
                      placeholder={
                        usernameError
                          ? "Please provide a valid user name"
                          : "Please enter your name"
                      }
                      onChange={(e) => {
                        setLocalUsername(e.target.value);
                      }}
                    />
                  </motion.div>
                  <motion.div
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{
                      duration: 0.3,
                      delay: 0.2,
                      ease: [0, 0.71, 0.2, 1.01],
                      scale: {
                        type: "spring",
                        damping: 20,
                        stiffness: 100,
                        restDelta: 0.001,
                      },
                    }}
                  >
                    <input
                      id="mobile"
                      style={{
                        marginTop: "1.313rem",
                        border: usernameError ? "1px solid red" : "",
                      }}
                      placeholder={
                        usernameError
                          ? "Please provide a valid phone number"
                          : "Mobile Number"
                      }
                      onChange={(e) => {
                        setMobileNumber(e.target.value);
                      }}
                    />
                  </motion.div>
                </div>
                <div className="below-inputs">
                  {/* <div
                    className="below-text"
                    onClick={() => {
                      // const uniqueId = uuidv4();
                      // localStorage.setItem("username", uniqueId);
                      // localStorage.setItem(
                      //   "email",
                      //   `${uniqueId}@${businessName}.com`
                      // );
                      props.onClose();
                      // setLoggedIn(true);
                    }}
                  >
                    Cancel
                  </div> */}
                  <motion.div
                    whileHover={{ scale: 1.04 }}
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{
                      duration: 0.3,
                      ease: [0, 0.71, 0.2, 1.01],
                      scale: {
                        type: "spring",
                        damping: 20,
                        stiffness: 100,
                        restDelta: 0.001,
                      },
                    }}
                  >
                    <Button
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        checkValidations();
                      }}
                    >
                      {" "}
                      Get in touch <img src={Arrow} alt="Login" />
                    </Button>
                  </motion.div>
                </div>
                {/* <button onClick={props.onClose}>Close</button> */}
              </div>
            ) : (
              <div>
                <Lottie animationData={Done} loop={false} />
                <center className="request-done">
                  Request submitted <br />
                  <div
                    style={{
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      props.onClose();
                    }}
                  >
                    close
                  </div>
                </center>
              </div>
            )}
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default Modal_live_support;
